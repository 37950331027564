import { createSelector } from '@ngrx/store';
import { TelematicLabState } from '..';

export namespace CommunicationResponseSelectors {
  const getCommunicationResponseState = (state: TelematicLabState) =>
    state.communicationResponse;

  export const getCommunicationResponseOverview = createSelector(
    getCommunicationResponseState,
    (state) => state.responseOverview,
  );
  export const getCommunicationResponseOverviewQuery = createSelector(
    getCommunicationResponseState,
    (state) => state.responseOverviewQuery,
  );
  export const getIsCommunicationResponseOverviewLoading = createSelector(
    getCommunicationResponseState,
    (state) => state.isResponseOverviewLoading,
  );
}
