/* eslint-disable sonarjs/no-identical-functions */
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LabService } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TelematicLabState } from '..';
import { RootActions } from '../root/root.actions';
import { RootSelectors } from '../root/root.selectors';
import { CommunicationResponseActions } from './communication-response.actions';
import { CommunicationResponseSelectors } from './communication-response.selectors';

@Injectable()
export class CommunicationResponseEffects {
  private actions$: Actions = inject(Actions);
  private store: Store<TelematicLabState> = inject(Store);

  LoadResponseOverviewByIdentifier$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationResponseActions.LoadResponseOverviewByIdentifier),
      withLatestFrom(
        this.store.select(
          CommunicationResponseSelectors.getCommunicationResponseOverviewQuery,
        ),
      ),
      switchMap(([payload, query]) =>
        this.communicationResponseService
          .getResponsesByRequestIdentifier(
            payload.payload.eid,
            payload.payload.equipmentNumber,
            payload.payload.requestIdentifier,
            query.page,
            query.pageSize,
          )
          .pipe(
            map((data) =>
              CommunicationResponseActions.LoadResponseOverviewSuccess({
                payload: data,
              }),
            ),
            catchError(() => [
              CommunicationResponseActions.LoadResponseOverviewError(),
              RootActions.ShowErrorSnackbar(),
            ]),
          ),
      ),
    ),
  );

  LoadResponsesByUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationResponseActions.LoadResponseOverview),
      withLatestFrom(
        this.store.select(
          CommunicationResponseSelectors.getCommunicationResponseOverviewQuery,
        ),
      ),
      switchMap(([payload, query]) =>
        this.communicationResponseService
          .getResponsesByUser(payload.payload, query.page, query.pageSize)
          .pipe(
            map((data) =>
              CommunicationResponseActions.LoadResponseOverviewSuccess({
                payload: data,
              }),
            ),
            catchError(() => [
              CommunicationResponseActions.LoadResponseOverviewError(),
              RootActions.ShowErrorSnackbar(),
            ]),
          ),
      ),
    ),
  );

  $SaveResponseOverviewQuery: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationResponseActions.SaveResponseOverviewQuery),
      withLatestFrom(this.store.select(RootSelectors.getUsersMail)),
      switchMap(([, mail]) => [
        CommunicationResponseActions.LoadResponseOverview({
          payload: mail,
        }),
      ]),
    ),
  );

  $SaveResponseOverviewByIdentifierQuery: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CommunicationResponseActions.SaveResponseOverviewByIdentifierQuery,
        ),
        switchMap((payload) => [
          CommunicationResponseActions.LoadResponseOverviewByIdentifier({
            payload: payload.payload,
          }),
        ]),
      ),
  );

  constructor(private communicationResponseService: LabService) {}
}
