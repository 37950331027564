import { createAction, props } from '@ngrx/store';
import {
  ResponseOverviewByIdentifierPayload,
  OverviewQuery,
} from '../../models';
import { InstructionResponsePagedResult } from '@paldesk/shared-lib/data-access/provisioning-service-generated';

export namespace CommunicationResponseActions {
  export const LoadResponseOverview = createAction(
    '[TELEMATIC-LAB] LOAD_RESPONSE_OVERVIEW',
    props<{ payload: string }>(),
  );
  export const SaveResponseOverviewQuery = createAction(
    '[TELEMATIC-LAB] SAVE_RESPONSE_OVERVIEW_QUERY',
    props<{ payload: OverviewQuery }>(),
  );
  export const LoadResponseOverviewByIdentifier = createAction(
    '[TELEMATIC-LAB] LOAD_RESPONSE_OVERVIEW_BY_IDENTIFIER',
    props<{ payload: ResponseOverviewByIdentifierPayload }>(),
  );
  export const SaveResponseOverviewByIdentifierQuery = createAction(
    '[TELEMATIC-LAB] SAVE_RESPONSE_BY_IDENTIFIER_OVERVIEW_QUERY',
    props<{
      query: OverviewQuery;
      payload: ResponseOverviewByIdentifierPayload;
    }>(),
  );

  export const LoadResponseOverviewSuccess = createAction(
    '[TELEMATIC-LAB] LOAD_RESPONSE_OVERVIEW_SUCCESS',
    props<{ payload: InstructionResponsePagedResult }>(),
  );
  export const LoadResponseOverviewError = createAction(
    '[TELEMATIC-LAB] LOAD_RESPONSE_OVERVIEW_ERROR',
  );
}
