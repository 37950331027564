import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OverviewQuery } from '../../models';
import { InstructionResponsePagedResult } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { CommunicationResponseActions } from './communication-response.actions';

export interface CommunicationResponseState extends EntityState<any> {
  responseOverview: InstructionResponsePagedResult;
  responseOverviewQuery: OverviewQuery;
  isResponseOverviewLoading: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: CommunicationResponseState = adapter.getInitialState(
  {
    responseOverview: {} as InstructionResponsePagedResult,
    responseOverviewQuery: { page: 0, pageSize: 10 } as OverviewQuery,
    isResponseOverviewLoading: true,
  },
);

const _communicationResponseReducer = createReducer(
  initialState,
  on(CommunicationResponseActions.LoadResponseOverview, (state) => ({
    ...state,
    isResponseOverviewLoading: true,
  })),
  on(
    CommunicationResponseActions.LoadResponseOverviewByIdentifier,
    (state) => ({
      ...state,
      isResponseOverviewLoading: true,
    }),
  ),
  on(
    CommunicationResponseActions.SaveResponseOverviewQuery,
    (state, { payload }) => ({
      ...state,
      responseOverviewQuery: payload,
    }),
  ),
  on(
    CommunicationResponseActions.SaveResponseOverviewByIdentifierQuery,
    (state, { query }) => ({
      ...state,
      responseOverviewQuery: query,
    }),
  ),
  on(
    CommunicationResponseActions.LoadResponseOverviewSuccess,
    (state, { payload }) => ({
      ...state,
      responseOverview: payload,
      isResponseOverviewLoading: false,
    }),
  ),
  on(CommunicationResponseActions.LoadResponseOverviewError, (state) => ({
    ...state,
    isResponseOverviewLoading: false,
  })),
);
export function communicationResponseReducer(
  state,
  action: Action,
): CommunicationResponseState {
  return _communicationResponseReducer(state, action);
}
